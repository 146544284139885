<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <form class="needs-validation" @submit.prevent="Form">
            <div class="card-body">
              <div class="row mt-2">
                <h5 class="font-size-14 mb-1"></h5>
                <div class="col-md-4 col-sm-6">
                  <div class="mb-3 position-relative">
                    <code>* </code><label for="branchId">สาขา:</label>
                    <multiselect
                      id="branchId"
                      v-model="tooltipform.branchId"
                      :options="localData"
                      :show-labels="false"
                      :class="{
                        'is-invalid':
                          submitform && $v.tooltipform.branchId.$error,
                      }"
                      label="nameTh"
                      track-by="nameTh"
                    >
                      <span slot="noResult">ไม่พบข้อมูล</span></multiselect
                    >

                    <div
                      v-if="submitform && $v.tooltipform.branchId.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.tooltipform.branchId.required">{{
                        error
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-sm-4">
                  <div class="mb-3 position-relative">
                    <code>* </code>
                    <label for="nameTh">ประเภทของทีม </label>:
                    <multiselect
                      v-model="tooltipform.type"
                      :options="optionTeam"
                      label="name"
                      track-by="id"
                      :show-labels="false"
                      placeholder=""
                      :class="{
                        'is-invalid': submitform && $v.tooltipform.type.$error,
                      }"
                    >
                    </multiselect>
                    <div
                      v-if="submitform && $v.tooltipform.type.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.tooltipform.type.required">{{
                        error
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-sm-4">
                  <div class="mb-3 position-relative">
                    <code>* </code>
                    <label for="name">ชื่อทีม</label>:
                    <b-form-input
                      v-model="tooltipform.name"
                      placeholder=""
                      class="form-control"
                      :class="{
                        'is-invalid': submitform && $v.tooltipform.name.$error,
                      }"
                    ></b-form-input>
                    <div
                      v-if="submitform && $v.tooltipform.name.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.tooltipform.name.required">{{
                        error
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-end">
                <button type="submit" class="btn btn-success">บันทึก</button>
              </div>
            </div>
          </form>

          <hr />
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import {
  required,
  // email,
  // minLength,
  // sameAs,
  // maxLength,
  // numeric,
  // url,
  // alphaNum,
} from "vuelidate/lib/validators";

import Swal from "sweetalert2";
import useNetw from "@useNetw";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
// import DatePicker from "vue2-datepicker";

/**
 * Form validation component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
    // DatePicker,
    /*Switches,
    NumberInputSpinner,*/
  },
  page: {
    title: appConfig.team,
    meta: [
      {
        name: "description",
        content: appConfig.team,
      },
    ],
  },

  data() {
    return {
      Hide: false,
      enabled: false,
      submitform: false,
      submitformAddress: false,
      modalUpdateAddress: false,
      submitUpdateAddress: false,

      title: "เพิ่มทีม",
      items: [
        {
          text: "ฝ่ายขาย",
          active: true,
        },

        {
          text: appConfig.team,
          href: "/team",
        },
        {
          text: "เพิ่มทีม",
          active: true,
        },
      ],

      data: this.$route.params.data,
      localDataBranch: [], //เป็น-array Id
      localData: [],
      value: null,
      value1: null,
      dataTest: [],
      options: [],

      startIndex: "",
      endIndex: "",
      loading: undefined,
      total_pages: "",
      page: "",
      per_page: "",
      totalRecord: "",
      rowData: [], //? Data ที่ต่อ API
      // rowUser: [],
      rowAddress: [],
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50, 100],
      yearOption: [],
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      paginatedData: "",
      filter: {
        nameThPros: "",
        familyNameThPros: "",
        licensePlate: "",
        regCode: "",
        familyNameTh: "",
        nameTh: "",
        regDate: "",
        paymentCode: "",
      },
      selectedUsers: [],
      isSelectedAll: false,

      selectMode: "multi",
      selected: [],

      totalPageVe: "",
      perPageVe: 10,
      currentPageVe: 1,
      totalRecordVe: "",
      pageOptionsVe: [5, 10, 25, 50, 100],

      totalPagePros: "",
      perPagePros: 10,
      currentPagePros: 1,
      totalRecordPros: "",
      pageOptionsPros: [5, 10, 25, 50, 100],

      error: "กรุณาตรวจสอบข้อมูลอีกครั้ง.",

      optionTeam: [{ name: "ทีมขาย", id: "SALE" }],
      mobilePhone: "",
      vehicaleOption: [],
      contactTypeOption: [],
      customerOption: [],
      prospectOption: [],
      cusSrcOption: [],
      prefixOPtion: [],
      provinces: [],
      amphures: [],
      districts: [],
      index: "",
      tooltipform: {
        branchId: "",
        type: "",
        name: "",
      },
      provinceId: "",
      amphurId: "",
      districtId: "",
    };
  },
  validations: {
    tooltipform: {
      branchId: {
        required,
      },
      type: {
        required,
      },
      name: {
        required,
      },
    },
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
      if (user.branchId == item.branchId) {
        this.tooltipform.branchId = {
          branchId: item.branchId,
          nameTh: item.nameTh,
        };
      }
    });
  },
  computed: {
    fullAddress: function () {
      //

      return `${
        this.tooltipform.buildingNumber != ""
          ? "" + this.tooltipform.buildingNumber
          : this.tooltipform.buildingNumber
      } ${
        this.tooltipform.townshipNumber != ""
          ? "หมู่" + " " + this.tooltipform.townshipNumber
          : this.tooltipform.townshipNumber
      } ${
        this.tooltipform.townshipName != ""
          ? "" + this.tooltipform.townshipName
          : this.tooltipform.townshipName
      } ${
        this.tooltipform.subLane != ""
          ? "ตรอก" + " " + this.tooltipform.subLane
          : this.tooltipform.subLane
      } ${
        this.tooltipform.lane != ""
          ? "ซอย" + " " + this.tooltipform.lane
          : this.tooltipform.lane
      } ${
        this.tooltipform.streetName != "" &&
        this.tooltipform.streetName != undefined
          ? "ถนน" + " " + this.tooltipform.streetName
          : ""
      } ${
        this.districtId != "" &&
        this.districtId != undefined &&
        this.districtId != null &&
        this.districtId.nameTh != "" &&
        this.districtId.nameTh != undefined &&
        this.districtId.nameTh != null
          ? "ตำบล" + this.districtId.nameTh
          : ""
      } ${
        this.amphurId != "" &&
        this.amphurId != undefined &&
        this.amphurId != null &&
        this.amphurId.nameTh != "" &&
        this.amphurId.nameTh != undefined &&
        this.amphurId.nameTh != null
          ? "อำเภอ" + this.amphurId.nameTh
          : ""
      } ${
        this.provinceId != "" &&
        this.provinceId != null &&
        this.provinceId != undefined &&
        this.provinceId.nameTh != null &&
        this.provinceId.nameTh != "" &&
        this.provinceId.nameTh != undefined
          ? "จังหวัด" + this.provinceId.nameTh
          : ""
      }  ${
        this.tooltipform.postCode != ""
          ? "รหัสไปรษณีย์" + " " + this.tooltipform.postCode
          : ""
      }
      `;
    },
  },
  created() {
    this.inittahun();
    this.getLocalData()
  },
  methods: {
    inittahun() {
      let currentYear = new Date().getFullYear();
      let earliestYear = 2001;
      let years = [];
      let data = "";
      while (currentYear >= earliestYear) {
        data = { nameTh: currentYear };
        years.push(data);
        currentYear -= 1;
      }
      this.yearOption = years;
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    checkMonth(data) {
      const variableOne = this.optionMonth.filter(
        (itemInArray) => itemInArray.id === data
      );
      return variableOne[0].name;
      // console.log(variableOne);
    },
    select(data) {
      this.data = data[0];
      this.tooltipform.ppCusId = {
        nameTh: `${this.data.nameTh != null ? this.data.nameTh : ""} ${
          this.data.familyNameTh != null ? this.data.familyNameTh : ""
        }`,
        ppCusId: this.data.ppCusId,
      };
      this.tooltipform.mobilePhone = this.data.mobilePhone;
      this.tooltipform.email = this.data.email;

      this.tooltipform.cusId = "";
      this.$refs["modalPropect"].hide();
    },

    selectCus(data) {
      // const arr = [];
      this.data = data[0];
      this.tooltipform.cusId = {
        nameTh: `${this.data.nameTh != null ? this.data.nameTh : ""} ${
          this.data.familyNameTh != null ? this.data.familyNameTh : ""
        }`,
        cusId: this.data.cusId,
      };
      this.tooltipform.mobilePhone = this.data.mobilePhone;
      this.tooltipform.email = this.data.email;
      this.tooltipform.ppCusId = "";
      this.$refs["modalCus"].hide();
    },

    selectVe(data) {
      this.data = data[0];

      this.tooltipform.vehicleId = {
        licensePlate: this.data.licensePlate,
        vehicleId: this.data.vehicleId,
      };
      this.tooltipform.vin = this.data.vin;
      this.tooltipform.vehicleModelTh = this.data.vehicleModelTh;
      this.tooltipform.vehicleBrandTh = this.data.vehicleBrandTh;
      this.$refs["modalVe"].hide();
    },
    selectCusNoArr(item) {
      this.tooltipform.mobilePhone = item.mobilePhone;
      this.tooltipform.email = item.email;
      this.tooltipform.ppCusId = "";
    },
    selectNoArr(item) {
      this.tooltipform.mobilePhone = item.mobilePhone;
      this.tooltipform.email = item.email;

      this.tooltipform.cusId = "";
    },
    selectVeNoArr(item) {
      this.tooltipform.vin = item.vin;
      this.tooltipform.vehicleModelTh = item.vehicleModelTh;
      this.tooltipform.vehicleBrandTh = item.vehicleBrandTh;
    },

    customLabelCus({ nameTh, familyNameTh }) {
      return `${nameTh != null ? nameTh : ""}  ${
        familyNameTh != null ? familyNameTh : ""
      }`;
    },
    ChangeNameTh(nameTh) {
      if (nameTh.nameTh === "นาย") {
        this.tooltipform.prefixIdEn = "Mr.";
      } else if (nameTh.nameTh === "นาง") {
        this.tooltipform.prefixIdEn = "Mrs.";
      } else {
        this.tooltipform.prefixIdEn = "Miss.";
      }
    },

    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      this.branchItem = user.branchId;

      const localDataBranch = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item.branchId);
      });
      this.localDataBranch = localDataBranch;
    },

    Form() {
      this.$v.$touch();
      this.submitform = true;
      if (this.$v.tooltipform.$invalid != true) {
        this.postData();
      }
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.rowAddress = filteredItems.length;
      this.currentPage = 1;
    },

    onRowSelected(rowData) {
      this.selected = rowData;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },

    postData: function () {
      this.overlayFlag = true;

      useNetw
        .post("api/team/store", {
          branchId: this.tooltipform.branchId.branchId,
          type: this.tooltipform.type.id,

          name: this.tooltipform.name,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          // btoa(
          this.$router.push({
            name: "edit-team",
            params: {
              teamId: btoa(response.data.teamId),
            },
          });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },

    clearAddressPost() {
      this.addressform.buildingNumber = "";
      this.addressform.townshipNumber = "";
      this.addressform.townshipName = "";
      this.addressform.subLane = "";
      this.addressform.lane = "";
      this.addressform.streetName = "";
      this.addressform.districtId = "";
      this.addressform.amphurId = "";
      this.addressform.provinceId = "";
      this.addressform.postCode = "";
      // this.fullAddress = "";
      this.submitformAddress = false;
    },

    handleSearch() {
      if (this.tooltipform.branchId === "") {
        this.branchId;
      } else {
        this.branchId = this.tooltipform.branchId.branchId;
      }
      this.getCustomer();
    },
    handleSearchVe() {
      if (this.tooltipform.branchId === "") {
        this.branchId;
      } else {
        this.branchId = this.tooltipform.branchId.branchId;
      }
      this.getVehical();
    },
    handleSearchPros() {
      if (this.tooltipform.branchId === "") {
        this.branchId;
      } else {
        this.branchId = this.tooltipform.branchId.branchId;
      }
      this.getProspect();
    },

    handleChangePage(page) {
      this.currentPage = page;
      this.getCustomer();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getCustomer();
    },
    handleChangePageVe(page) {
      this.currentPageVe = page;
      this.getVehical();
    },
    handlePageChangeVe(active) {
      this.perPageVe = active;
      this.currentPageVe = 1;
      this.getVehical();
    },

    handleChangePagePros(page) {
      this.currentPagePros = page;
      this.getProspect();
    },
    handlePageChangePros(active) {
      this.perPagePros = active;
      this.currentPagePros = 1;
      this.getProspect();
    },
  },
};
</script>
<style scoped>
.input-placeholder {
  font-size: 12px;
}
</style>
